export const technicalGroup = {
  heading: "Technical Groups",
  content: `Engineering shouldn’t be limited to academics, and those with a passion to learn new things thrive for a community with support and guidance. Tech groups at IITR provide you with exactly that. 
    From Software Development, Competitive Programming to DeepLearning, Quantum Computing, and InfoSec, you will find avenues to go beyond classroom lectures and explore your interest. Although many clubs conduct rigorous tests for their recruitment, some are open and your commitment level is entirely up to you. So whether you want to write geeky articles, develop an indie game, make your own blockchain or hack Connect-e-Dil there’s always a community waiting for you.`,
}

export const culturalGroup = {
  heading: "Cultural Groups",
  content:
    "As passionate humans, we all love to sing, dance, mimic and act. While engineering and science are noble pursuits and necessary to sustain life, music, art, and poetry are what we stay alive for. The cultural society provides a stage to showcase your talents and helps to break away from the shackles of innumerable projects, assignments and quizzes. The various groups that are the limbs of the cultural society help you in embracing your inner artists. Be it bringing various characters to life, exhibiting new dance moves, composing new melodies or being a part of brainstorming sessions, you will always find a group of your interest.",
}
export const interIITs = [
  {
    title: "Sports",
    content:
      "<p>The oldest and most prestigious of all the inter-IIT meets is the Sports meet. One of the most awaited calendar events, the main event takes place in December, while a separate aquatics meet happens in October. Athletes from all the 23 IITs converge at the host IIT to compete for the cup of the longest-running Inter-collegiate meet. Be it cricket, football, chess, or TT, squash, and badminton, almost all the sports championships are up for grab. After 10 long days of passion, emotion, and drama, we have an overall champion that takes that trophy home. In 2021 IIT Roorkee and Delhi were supposed to organize the event, but it was canceled due to COVID situations.</p>",
  },
  {
    title: "Cultural",
    content:
      "<p>Cultural meet or the cult meet is the latest of all the Inter-IIT meets. Started in 2016, it is still in its infancy. But don't let this fool you about the hype around this event. It's an established platform to showcase cultural activities and artistic flair across IITs in a 4 days culmination of modern drama, dance, Fine Arts, and Design. In 2018, IITR hosted this event which, for the first time, witnessed participation from all 23 IITs and an overwhelming footfall of 2300 students. We were the second runner-up in 2019's cult meet held at IIT Bombay</p>",
  },
  {
    title: "Technical",
    content:
      "<p>For the geeky ones among you, the chance to bring ultimate glory to the institute is there in the form of inter-IIT Tech meet. Started in 2012, it happens each year in the month of March. Events are divided into three categories: high-prep, mid-prep, and low-prep, indicating the amount of preparation and team size required. With sponsors ranging from <> you get a chance to travel to other IITs and compete with the brightest minds in the country. IITR was the host and overall champion in 2019 with 4 gold medals.</p>",
  },
]

export const sports = [
  {
    title: "Swimming Pool",
    content:
      "<p>IITR boasts an Olympic size swimming pool that is open for 7+ months, i.e., from July to October and then March to May. This open pool is situated near the main building. Whether you are a complete beginner or an experienced swimmer, there are coaches that can help you touch that finish line faster. The insti team also has expert coaches and separate timings to train better. So come here and show off that butterfly stroke of yours or learn to play water polo.</p>",
  },
  {
    title: "LBS",
    content:
      "<p>Lal Bahadur Shastri Stadium is the center point of all the field games in the institute. Being the biggest stadium on campus, it comprises of athletic cum jogging track and the cricket field. Well equipped with sightscreens, floodlights, and an air-conditioned pavilion, this sure is a delight to cricket players and enthusiasts alike. Visit the stadium, and you'll see the names of all the people who have bought glory to the institute and also an autographed photograph of Kapil Dev and various other cricketing legends.</p>",
  },
  {
    title: "Football and Hockey Ground",
    content:
      "<p>The Major Dhyan Chand Stadium, located next to LBS, is where you can play football and hockey. The land is divided into two parts and is very well lit. From friendly matches to the heated inter-IIT clashes, every major game takes place here. So don in that Barcelona t-shirt of yours and join the fun in the morning. Don't forget to take a breakfast Chapo from your seniors.</p>",
  },
  {
    title: "Sports Complex",
    content:
      "<p>The sports complex of IITR is one of the oldest and feature-rich among all the leading institutes in the country. It houses facilities ranging from badminton, tennis, squash, TT to sports like rowing, gymnastic, kho kho, yoga, and weightlifting. All are well maintained and are usually open during college hours and in the morning. Apart from these central facilities, there's no shortage of playing areas on the campus. Badminton courts, TT tables, etc. are also available in most of the Bhawans</p>",
  },
  {
    title: "Central Gym",
    content:
      "<p>For those of you planning to go to a gym and improving your physique, the central gym has got you covered. This two-storeyed building located near <> is packed with modern machines and a powerful music system. Trainers are available in the evening to guide you and see that you don’t overstress your body. With a powerful music system also available, you are all set. So grab your trainers, blast van Halen and get going.</p>",
  },
]

export const nccnsonss = [
  {
    title: "National Cadet Corps",
    intro:
      "<p>NCC is the student wing of the Indian Armed Forces. It was formed on July 15, 1948. It is the largest uniformed non-military organization in the world. </p>",
    iitr:
      "<p>It is one of the oldest NCC in the country. The NCC IITR is a technical unit, and its name is 3UK CTR (Uttaranchal Composite Technical Regiment). <br>NCC conduct many social, cultural and sports activities along with training. Some activities organized by NCC are firing camp, awareness rally, nukkad natak, debate competition etc.<br>As training is an important part of NCC therefore training camps are held which provide self-defense training, military training with rifles, extensive knowledge of devices used in army etc.</p>",
  },
  {
    title: "National Service Scheme",
    intro:
      "<p>Some of you may call us life saviors, for some we may be the only source of hope. But in reality, we are just an organization of over 1000 active & dedicated members having a common goal- “Striving for the betterment of our motherland by fully indulging ourselves in the service of mankind”. NSS IIT Roorkee is the largest student-body group of IIT Roorkee that brings an entirely new dimension to this prestigious institute. We are an organization consisting of 10 well-coordinated cells through which we keep on contributing to several domains of social service. Of the many things that make us who we are, what drives us from within is what inspires us to work. Driven by a strong urge to help the needy, supplemented with great support from the students and sponsors, we aim to continue creating an impact on the lives of many thereby following our motto- “ NOT FOR ME, BUT FOR THE NATION ”.</p>",
    iitr:
      "<p>We hope to make a change in our society on a national level by inculcating thoughts of social welfare among the people. We wish towards building a beautiful world and spreading the message of environmental preservation, peace and education among today's youth. NSS IIT Roorkee works on a diverse range of social issues including blood donation, spreading awareness, environmental issues, spreading education at all levels, etc. We also collaborate with several NGOs in and around. NSS IIT Roorkee primarily aims to work for the betterment of society and instill the spirit of social service among the young students. It organizes blood donation camps, AIDS awareness camps, informative lectures on human values, surveys, guest lectures on general topics, and other such events regularly at all levels. We have many cells under us like-<br>- Primary Education<br>- Event Management and Promotion<br>- Swach Bharat Abhiyaan<br>- Awareness<br>- Women Empowerment<br>- NGO & Relation<br>- Exam education<br>- RTE<br>- Pragati<br>All these cells collectively work together to help in the development and growth in all areas.Thus we work with a single thought in our minds: ”BE THE CHANGE, YOU WISH TO SEE”.</p>",
  },
  {
    title: "National Sports Organization",
    intro:
      "NSO is the most popular option among students. No surprise there. Don’t we all love a good game? NSO offers the option of choosing from a variety of sports spanning cricket, athletics, frisbee, yoga and weightlifting, aquatics to name a few.The criterion for selection varies depending on the sport, the instructor and the number of people who turn up for the try-outs. In many cases, it is not essential to have prior experience in the sport to get selected.",
    iitr:
      "Upon arriving to the campus, you are pushed into making the life altering decision of choosing between the holy trinity of “free” credits i.e. NCC, NSO and NSS. Though getting into NCC or NSS is basically a coinflip, to claim the right to the elysian fields of NSO (acronym for National Sports Organisation) requires a bit more competence and athleticism. It is a great way to keep fit and active but can turn into a huge burden since the attendance criteria applies to NSO same as any other course you are enrolled in. Regularity in practice (easier said than done) and competency in your sport might end up with you en route to compete at the Inter IIT Sports meet.",
  },
]
