import React from "react"
import Layout from "../../components/layout"
import { nccnsonss } from "../../data/co-curricular"
import "../../styles/scholarships.css"
import SEO from "../../components/seo"
function Scholarships(props) {
  return (
    <Layout>
      <SEO
        title="NCC/NSO/NSS"
        description="It is one of the oldest NCC in the country. The NCC IITR is a technical unit, and its name is 3UK CTR (Uttaranchal Composite Technical Regiment).
        NCC conduct many social, cultural and sports activities along with training. Some activities organized by NCC are firing camp, awareness rally, nukkad natak, debate competition etc."
      />
      {nccnsonss.map(program => (
        <div>
          <h2 className="dark-color-head">{program.title}</h2>
          <h2 className="normal-head noto">Introduction to the program</h2>
          <div
            className="normal-para"
            dangerouslySetInnerHTML={{
              __html: program.intro,
            }}
          />
          <h2 className="normal-head noto">{program.title} in IITR</h2>
          <div
            className="normal-para"
            dangerouslySetInnerHTML={{
              __html: program.iitr,
            }}
          />
        </div>
      ))}
    </Layout>
  )
}

export default Scholarships
